import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  getOrganizationProfileLogsApi,
  getOrganizationResourcesLogsApi,
  getOrganizationTransactionsLogsApi,
  getOrganizationsLogsApi,
  getResourcesLogsApi,
  getTagsLogsApi,
} from 'src/services/api/system';
import useLogsListQuery from './../queries/useLogsListQuery';
import { ROUTES } from 'src/services/navigation/constants';
import { useReplaceUserRoutesParams } from 'src/hooks/useReplaceUserRoutesParams';
import { generateQueryObj, generateQueryString } from 'src/utils';
import { history } from 'src/services/navigation';

export const useHandleScreenLogs = ({ currentScreen, filters }) => {
  const { id, orgId } = useParams();
  const { search } = useLocation();

  const { userProfileRoute, userTransactionsRoute, userResourcesRoute } =
    useReplaceUserRoutesParams({ id, orgId });

  const getscreenLogsApi = useMemo(() => {
    if (!getOrganizationsLogsApi) {
      return () => {
        // eslint-disable-next-line no-console
        console.log(
          `getOrganizationsLogsApi is not loaded yet, it's ${typeof getOrganizationsLogsApi}`
        );
      };
    }
    if (!getOrganizationProfileLogsApi) {
      return () => {
        // eslint-disable-next-line no-console
        console.log(
          `getOrganizationProfileLogsApi is not loaded yet, it's ${typeof getOrganizationProfileLogsApi}`
        );
      };
    }
    if (!getOrganizationTransactionsLogsApi) {
      return () => {
        // eslint-disable-next-line no-console
        console.log(
          `getOrganizationTransactionsLogsApi is not loaded yet, it's ${typeof getOrganizationTransactionsLogsApi}`
        );
      };
    }
    if (!getOrganizationResourcesLogsApi) {
      return () => {
        // eslint-disable-next-line no-console
        console.log(
          `getOrganizationResourcesLogsApi is not loaded yet, it's ${typeof getOrganizationResourcesLogsApi}`
        );
      };
    }
    if (!getResourcesLogsApi) {
      return () => {
        // eslint-disable-next-line no-console
        console.log(
          `getResourcesLogsApi is not loaded yet, it's ${typeof getResourcesLogsApi}`
        );
      };
    }
    if (!getTagsLogsApi) {
      return () => {
        // eslint-disable-next-line no-console
        console.log(
          `getTagsLogsApi is not loaded yet, it's ${typeof getTagsLogsApi}`
        );
      };
    }
    const LOGS_APIS = {
      [ROUTES.USERS?.replace(/\//g, '')]: getOrganizationsLogsApi,
      [userProfileRoute?.replace(/\//g, '')]: getOrganizationProfileLogsApi,
      [userTransactionsRoute?.replace(/\//g, '')]:
        getOrganizationTransactionsLogsApi,
      [userResourcesRoute?.replace(/\//g, '')]: getOrganizationResourcesLogsApi,
      [ROUTES.RESOURCES?.replace(/\//g, '')]: getResourcesLogsApi,
      [ROUTES.TAGS?.replace(/\//g, '')]: getTagsLogsApi,
    };
    return (
      LOGS_APIS[currentScreen?.trim()?.replace(/\//g, '')] ??
      (() => {
        // eslint-disable-next-line no-console
        console.log(
          `No logs api found for this screen ${currentScreen
            ?.trim()
            ?.replace(/\//g, '')}. Available screens are ${Object.keys(
            LOGS_APIS
          )}`
        );
        // eslint-disable-next-line no-console
        console.log(Object.keys(LOGS_APIS).includes(currentScreen));
      })
    );
  }, [
    currentScreen,
    userProfileRoute,
    userTransactionsRoute,
    userResourcesRoute,
  ]);

  const { show_logs } = generateQueryObj(search);
  const isModalShown = Boolean(show_logs);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useLogsListQuery({
    currentScreen,
    filters,
    getscreenLogsApi,
    enabled: isModalShown,
  });

  const handleOpenLogs = () => {
    const currentSearchObj = generateQueryObj(search);

    history.push({
      search: generateQueryString({
        ...currentSearchObj,
        show_logs: true,
      }),
    });
  };

  const handleCancel = () => {
    const currentSearchObj = generateQueryObj(search);
    delete currentSearchObj.show_logs;

    const newSearch = generateQueryString(currentSearchObj);
    history.push({ search: newSearch });
  };

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    handleOpenLogs,
    handleCancel,
    isModalShown,
  };
};
