import { useInfiniteQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { handleError } from 'src/services/errorHandling';
import { FAILED_GETTING_LOGS } from 'src/services/errorHandling/constants';

const useLogsListQuery = ({
  currentScreen,
  filters,
  getscreenLogsApi,
  enabled,
}) => {
  const { orgId } = useParams();
  return useInfiniteQuery(
    ['activity-logs', currentScreen, filters],
    ({ pageParam: pageNumber = 1, signal, pageSize = 20 }) =>
      getscreenLogsApi?.({ filters, pageNumber, pageSize, orgId }, signal),

    {
      onError: (error) => handleError(error, FAILED_GETTING_LOGS),
      getNextPageParam: (lastPage) => {
        const { page, totalPages } = lastPage?.data?.pagination || {};

        const shouldStop = page >= totalPages;

        if (shouldStop) return undefined;
        return page + 1;
      },
      retry: 0,
      enabled,
    }
  );
};

export default useLogsListQuery;
